import React from 'react';
import './LandingPage.css';
import EmailList from '../Components/Sections/EmailList';
import PrimaryBtn from '../Components/Buttons/PrimaryBtn';
import Watch from "../Assets/Watch.svg"
import Tools from "../Assets/Tools.svg"
import Money from "../Assets/Money.svg"
import Translate from "../Assets/Translate.svg"
import MiniCard from '../Components/Elements/MiniCard';
import Testimonials from '../Components/Sections/Testimonials';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function HomePage() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const handleStart = () => {
    navigate("/Pricing")
  };
  const handleDemoVideo = () => {
    window.open("https://www.youtube.com/watch?v=yAoLSRbwxL8&pp=ygUPZGVtb1ZpZGVvIGR1bW15", "_blank")
  };

  return (
    <div>
      <div className='LandingPageContent'>

        <div className={language === 'en' ? 'MessageImgEn' : "MessageImgAr"}></div>

        <div className='LandingSlogan' dangerouslySetInnerHTML={{ __html: t("Slogan") }} />

        <div className='LandingDescription' dangerouslySetInnerHTML={{ __html: t("Slogan Description") }} />

        <div className='TwoBtns'>
          <PrimaryBtn text={t("Demo Video")} icon={Watch} onClick={handleDemoVideo} type="WhiteBtn" />
          <PrimaryBtn text={t("Start Now" )} onClick={handleStart} type="BlueBtn" />
        </div>

        <div className={language === 'en'  ? "LandingAppUiEn" : "LandingAppUiAr"}></div>

        <div className="MiniCardsSectionBg">
          <div className='MiniCardsSection'>
            <MiniCard text={<span dangerouslySetInnerHTML={{ __html: t("No hidden fees") }}/>} icon={Money} />
            <MiniCard text={<span dangerouslySetInnerHTML={{ __html: t("Support Arabic & English") }}/>} icon={Translate} />
            <MiniCard text={<span dangerouslySetInnerHTML={{ __html: t("All tools you need") }}/>} icon={Tools} />
          </div>
        </div>

        <Testimonials />

        <PrimaryBtn text={t("Start Now" )} onClick={handleStart} type="BlueBtn" />

      </div>
      <EmailList />
    </div>
  );
}
