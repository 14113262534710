import React from 'react'
import "./ButtonSection.css"
import PrimaryBtn from '../Buttons/PrimaryBtn'

export default function ButtonSection({message, ...restProps}) {
	return (
		<div className='UploadSection'>
				<PrimaryBtn {...restProps}/>
				<div className='BlueMessage'>{message}</div>
			</div>
	)
}
