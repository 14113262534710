import React, { useEffect, useState } from 'react'
import InvoiceDetailsSection from '../Components/Invoice/InvoiceDetailsSection'
import PaymentForm from '../Components/Forms/PaymentForm'
import PrimaryBtn from '../Components/Buttons/PrimaryBtn'
import BlueUploadIcon from "../Assets/Upload.svg"
import WhiteUploadIcon from "../Assets/WhiteUpload.svg"

import Dummy from "../Assets/Dummy.png" //delete later
import InvoiceTitle from '../Components/Invoice/InvoiceTitle'
import InvoiceSignature from '../Components/Invoice/InvoiceSignature'
import ButtonSection from '../Components/Sections/ButtonSection'
import { useNavigate, useParams } from 'react-router-dom'
import DisplayInfoForm from '../Components/Forms/DisplayInfoForm'
import { deleteData, getData, putData } from '../Fetch'
import Loading from '../Components/Elements/Loading'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function InvoicePayment() {
	const navigate = useNavigate();
	const { pid } = useParams();
	const [invoiceData, setInvoiceData] = useState(null);
	const [linkData, setLinkData] = useState(null);
	const [ownerData, setOwnerData] = useState(null);
	const [activeMethod, setActiveMethod] = useState('Cliq');	

	const [loading, setLoading] = useState(true);

	const [file, setFile] = useState(null);
	const [fileAlert, setFileAlert] = useState(false);

	useEffect(() => {
		if (!pid?.startsWith("i")) {
			navigate("/")
		}
		const fetchInvoiceData = async () => {
			try {
				const linkData = await getData(`/links/${pid}`);
				if (!linkData) {
					alert("Link not found");
					navigate("/")
				}
				setLinkData(linkData);
				const invoiceData = await getData(`/users/${linkData.uid}/invoices/${linkData.oid}`);
				setInvoiceData(invoiceData);
				const ownerData = await getData(`/users/${linkData.uid}/account/`);
				setOwnerData(ownerData);
				isEnglish = invoiceData?.language === "en";
				setLoading(false);
			} catch (error) {
				console.error('Error fetching invoice data:', error);
			}
		};
		fetchInvoiceData();
	}, [pid]);

	let isEnglish = false;

	const handleClick = () => {
		alert("Clicked")
	}

	const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
        setFileAlert(true);
        return;
    }

    try {

				setLoading(true);	

        // Check file size
        if (file.size > 2 * 1024 * 1024) {
            alert('File size should be less than 2MB.');
            return;
        }

        // Upload file to Firebase Storage
				const storage = getStorage();
        const storageRef = ref(storage, `users/${linkData.uid}/invoices/${linkData.oid}/${file.name}`);
        await uploadBytes(storageRef, file);

        // Get download URL
        const downloadURL = await getDownloadURL(storageRef);
				
        // Update invoice status and other details
        const currentDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        await putData(`users/${linkData.uid}/invoices/${linkData.oid}/file`, {link:downloadURL, name: file.name});
        await putData(`users/${linkData.uid}/invoices/${linkData.oid}/status`, "onReview");
        await putData(`users/${linkData.uid}/invoices/${linkData.oid}/datePaid`, currentDate);
        await putData(`users/${linkData.uid}/invoices/${linkData.oid}/method`, activeMethod);
        await deleteData(`links/${pid}`);

        // Redirect to home page
        navigate("/");
				setLoading(false);
        alert("Successfully submitted invoice!");
    } catch (error) {
        console.error('Error submitting invoice:', error);
    }
};

	const handleHelp = () => {
		//navigate to whatsapp link with asking for help message
		window.open("https://wa.me/962791807251?text=I%20need%20help%20with%20Dafatir%20payment", "_blank")
	}

	const handleUpload = (event) => {
		const uploadedFile = event.target.files[0];
		setFile(uploadedFile);
	};

	if (loading)
		return <Loading />

	return (
		<form className='InvoicePayment FlexColumn' onSubmit={handleSubmit}>

			<InvoiceTitle title={false ? "" : "إشعار دفع إلكتروني"} image={Dummy} />

			<div className="InvoiceDate">{false ? "" : "تاريخ الإصدار: 24/03/2024"}</div>

			<InvoiceDetailsSection info={invoiceData?.info} />

			<DisplayInfoForm title={false ? "" : "بيانات العميل"} form={invoiceData?.form} />

			<PaymentForm CliqDetails={ownerData?.cliq} BankDetails={ownerData?.bank} activeMethod={activeMethod} setActiveMethod={setActiveMethod} />

			<input type="file" accept="image/*,.pdf,.doc,.docx" onChange={handleUpload} style={{ display: 'none' }} />
			<ButtonSection handleClick={() => { document.querySelector('input[type="file"]').click(); }} icon={file ? WhiteUploadIcon : fileAlert ? WhiteUploadIcon : BlueUploadIcon} message={file ? "*تم الرفع*" : "*الرجاء إرفاق ملف أو صورة تثبت تحويل المبلغ*"} text={false ? "" : "رفع ملف/صورة"} type={file ? "GreenBtn" : fileAlert ? "RedBtn" : "WhiteBtn"} />

			<div className='TwoBtns'>
				<PrimaryBtn text={false ? "Need Help" : "أحتاج مساعدة"} onClick={handleHelp} type="BlueBorderBtn" />
				<PrimaryBtn isSubmit text={false ? "Confirm" : "تم الدفع"} type="BlueBtn" />
			</div>
			<InvoiceSignature image={Dummy} OwnerName={"Nuha - نُهى"} />
		</form>
	)
}
