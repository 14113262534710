import React from 'react'
import "./Loading.css"
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';

export default function Loading() {
	const { t } = useTranslation();

	return (
		<div className='LoadingContainer'>
			<ReactLoading color='#005A8C' type='bars' />
			<h4 className='Blue'>{t("Loading Content")}</h4>
		</div>
	)
}
