import React from 'react'
import "./InvoiceSignature.css"

export default function InvoiceSignature({image, OwnerName}) {
	return (
		<div className='InvoiceSignature'>
			<img src={image} alt="Invoice Signature" />
			<div>{OwnerName}</div>
		</div>
	)
}
