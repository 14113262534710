import React, { useState } from 'react';
import "./CopyInput.css";

export default function CopyInput({ isEnglish, label, value, ...restProps }) {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <div className='CopyInputContainer'>
            <div className="AppLabel">{label}</div>
            <div className='CopyInput'>
                <input value={value} {...restProps} />
                <div className='CopyBtn' onClick={handleCopy}>{isEnglish ? "Copy" : "نسخ"}</div>
            </div>
            {copied && <div className='CopiedAlertContainer'><div className="CopiedAlert">{isEnglish ? "Text Copied" : "تم النسخ"}</div></div>}
        </div>
    );
}
