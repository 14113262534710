import React from 'react'
import "./Header.css"
import LanguageToggle from '../Buttons/LanguageToggle'
import SecondaryBtn from '../Buttons/SecondaryBtn';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Header({isAuth, setLoading, handleLogin, handleLogout}) {
  const navigate = useNavigate();
	const { t, i18n } = useTranslation();
  const language = i18n.language;

  return (
    <div className='Header'>
      <div className='HeaderContainer'>
        <div onClick={() => {navigate("/"); localStorage.setItem('redirect', 'false')}} className={language === "en" ? "HeaderLogoEN" : "HeaderLogoAR"}></div>
        <div className='HeaderActions'>
          {
            // <LanguageToggle setLoading={setLoading} />
          }
          <SecondaryBtn handleClick={isAuth? handleLogout : handleLogin} text={isAuth? t("Logout") : t("Login")} type={isAuth? "BlueBorderBtn" : "BlueBtn"} />
        </div>
      </div>
    </div>
  );
}
