import React from 'react'
import "./InvoiceTitle.css"

export default function InvoiceTitle({title, image}) {
	return (
		<div className="InvoicePageTitleContainer">
			<img className="InvoiceOwnerPicTop" src={image} alt='Invoice Owner Pic' />
			<div className="InvoicePageTitle">{title}</div>
		</div>
	)
}
