import React from 'react'
import "./PrimaryBtn.css"

export default function PrimaryBtn({ handleClick, text, icon, type, isSubmit, ...restProps }) {
	return (
		<button type={isSubmit?'submit':"button"} className={`PrimaryBtn ${type}`} onClick={handleClick} {...restProps}>
			{icon && <img alt='Button Icon' src={icon} className='PrimaryBtnIcon'/>}
			<div>{text}</div>
		</button>
	)
}
