import React, { useEffect } from 'react';
import "./InvoiceDetailsEditor.css";
import TextareaWithAutoResize from '../Elements/TextareaWithAutoResize';
import BulletTextareaWithAutoResize from '../Elements/BulletTextareaWithAutoResize';
import { use } from 'i18next';

export default function InvoiceDetailsEditor({ state, setState }) {
  useEffect(() => {
    if(!state.price?.currency){
      setState({ ...state, price: { ...state?.price, currency: 'JD' } });
    }
  }, [state]);

  return (
    <div className='InvoiceDetailsEditorSection'>
      <div className="InvoiceDetailsEditorTop">
        <TextareaWithAutoResize
        required
          placeholder='عنوان الفاتورة'
          onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
          value={state?.title}
          onChange={(e) => setState({ ...state, title: e.target.value })}
        />
        <div className='InvoiceEditorPrice'>

          <input
            required
            className="InvoiceEditorPriceNumber"
            placeholder='999'
            type='text'
            value={state?.price?.amount || ''}
            onKeyDown={(e) => {
              const isValidInput = /^[0-9٠١٢٣٤٥٦٧٨٩]*$/.test(e.key);
              const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']; // Allow Backspace, Delete, ArrowLeft, and ArrowRight keys
              // Allow Ctrl+A (Cmd+A on Mac) for "Select All"
              const isSelectAll = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'a';
              if (!isValidInput && !allowedKeys.includes(e.key) && !isSelectAll) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {

              // Replace non-English/Arabic numerals with empty string
              const englishArabicRegex = /^[0-9٠١٢٣٤٥٦٧٨٩]+$/;
              let inputValue = e.target.value;
              if (!englishArabicRegex.test(inputValue)) {
                const newValue = inputValue.replace(/[^0-9٠١٢٣٤٥٦٧٨٩]/g, '').replace(/^0+/g, '');
                setState({ ...state, price: { ...state?.price, amount: newValue } });
              }

              // Replace Arabic numerals with English numerals
              const arabicToEnglish = {
                '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
                '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9'
              };
              inputValue = e.target.value;
              const englishValue = inputValue.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (match) => arabicToEnglish[match]).replace(/^0+/g, '');
              setState({ ...state, price: { ...state?.price, amount: englishValue } });
            }}
          />
          <select
            className="InvoiceEditorPriceCurrency"
            value={state.price?.currency}
            onChange={(e) => setState({ ...state, price: { ...state?.price, currency: e.target.value } })}
            required
          >
            <option defaultValue value="JD">د.أ</option>
            <option value="AED">درهم</option>
            <option value="SAR">ر.س</option>
            <option value="$">$</option>
            <option value="£">£</option>
          </select>
        </div>
      </div>
      <div className="InvoiceDetailsEditorList">
        <BulletTextareaWithAutoResize
          type='Bullet'
          placeholder='تفاصيل الفاتورة'
          state={state}
          setState={setState}
          value={state?.list}
          onChange={(e) => setState({ ...state, list: e.target.value })}
        />
      </div>
    </div>
  );
}
