import React from 'react'
import "./Plan.css"
import PrimaryBtn from '../Buttons/PrimaryBtn'
import ListItem from './ListItem'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Plan({ handleClick, isFavorite, ...props }) {
	const { i18n } = useTranslation();
	const language = i18n.language;

	const title = language === "en" ? props.titleEN : props.titleAR;
	const list = language === "en" ? props.listEN : props.listAR;
	const currency = language === "en" ? props.currencyEN : props.currencyAR;
	const price = language === "en" ? `${currency} ${props.price}` : ` ${props.price} ${currency}`;
	const oldPrice = language === "en" ? `${currency} ${props.oldPrice}` : ` ${props.oldPrice} ${currency}`;

	return (
		<div className={isFavorite ? 'FavPlan' : "Plan"}>
			{isFavorite && <div className='FavStars'></div>}
			<div className='PlanTitle'>{title}</div>
			<div className='PlanList'>
				{list && list.map((item, i) => <ListItem color="Blue" key={i} text={item} />)}
			</div>
			<PrimaryBtn text="ابدأ الآن" type={isFavorite ? "BlueBtn" : "WhiteBtn"} handleClick={handleClick} />
			<div className="PlanPrice">{price}</div>
			<div className="PlanOldPrice">{oldPrice}</div>
		</div>
	)
}
