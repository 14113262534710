import React, { useEffect, useState } from 'react'
import { getAuth, GoogleAuthProvider, signInWithRedirect, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { getData, postData, putData } from '../Fetch';

import "./PricingPage.css"
import EmailList from '../Components/Sections/EmailList'
import Plan from '../Components/Elements/Plan'
import Countdown from '../Components/Sections/Countdown'
import { useNavigate } from 'react-router-dom'

import { Plans } from '../SupportData';
import Loading from '../Components/Elements/Loading';

export default function PricingPage({ setIsAuth }) {
  const [loading, setLoading] = useState(true);

  window.scrollTo(0, 0);

  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem('planID');
    const handleRedirect = async () => {
      localStorage.setItem('redirect', 'false');
      navigate("/payment/" + id);
      setLoading(false);
    };
  
    let redirect = localStorage.getItem('redirect');
    if(redirect !== 'true') setLoading(false);

    const auth = getAuth();
    const user = auth.currentUser;

    if (redirect === 'true' && user) {
      handleRedirect();
    }
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const redirect = localStorage.getItem('redirect');
      if (user && redirect === 'true') {
        handleRedirect();
      }
      else {
        setLoading(false);
      }
    });
  
    // Clean up subscription
    return () => unsubscribe();
  }, []);
  

  const handleLoginToContinue = async (id) => {
    setLoading(true);

    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      localStorage.setItem('planID', id);
      localStorage.setItem('redirect', 'true');

      // Sign in with redirect instead of popup
      await signInWithRedirect(auth, provider);
      // await signInWithPopup(auth, provider);

    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred during login. Please try again');
      setIsAuth(false);
      setLoading(false);
    }
  };


  if (loading) {
    return <Loading/>
  }

  return (
    <div className='PricingPage'>
      <div className='SectionTitle'>الباقات والأسعار</div>
      <div className="PlansContainer">
        {
          Plans.map((plan, index) => {
            return <Plan key={index} {...plan} handleClick={() => handleLoginToContinue(plan.id)} />
          })
        }
      </div>
      <Countdown />
      <EmailList />
    </div>
  )
}
