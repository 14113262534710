import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import "./App.css"
import Header from "./Components/Sections/Header";
import LandingPage from "./Pages/LandingPage";
import PricingPage from "./Pages/PricingPage";
import InvoicePayment from './Pages/InvoicePayment';
import InvoiceEditor from './Pages/InvoiceEditor';
import AccountSettings from './Pages/AccountSettings';
import NoticePage from './Pages/NoticePage';
import Alert from './Components/System/Alert';
import InvoiceCreator from './Pages/InvoiceCreator';
import AdminDashboard from './Pages/AdminDashboard';
import Loading from './Components/Elements/Loading';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import DafatirPayment from './Pages/DafatirPayment';

import { initializeApp } from "firebase/app";
import { getData } from './Fetch';

const firebaseConfig = {
  apiKey: "AIzaSyDLZcRVyLB0wIuXc4jJGNLMEuF_63F5j_Y",
  authDomain: "dafatir.co",
  projectId: "dafatir-b6fa9",
  storageBucket: "dafatir-b6fa9.appspot.com",
  messagingSenderId: "988872189077",
  appId: "1:988872189077:web:8722eddf6a746d5330679c"
};

const firebaseApp = initializeApp(firebaseConfig);

function App() {
  const [alertData, setAlertData] = useState({ isOn: false, title: "تنبيه", body: "تم حفظ التعديلات", type: "confirmation" });
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [isAuth, setIsAuth] = useState(false);

  const { i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    const uid = localStorage.getItem("uid")
    if (!uid) {
      setIsAuth(false);
      setLoading(false);
      return
    }

    const auth = getAuth(firebaseApp);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const uid = user.uid;
          const userData = await getData(`users/${uid}`);
          if (userData) {
            setUserData(userData);
            setIsAuth(true);
          } else {
            setIsAuth(false);
          }
        } catch (error) {
          setIsAuth(false);
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);

        }
      } else {
        setIsAuth(false);
        setLoading(false);
        localStorage.removeItem("uid")
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setIsAuth, setUserData]);


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      const auth = getAuth(firebaseApp);
      const provider = new GoogleAuthProvider();

      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential?.user;

      if (user) {
        const userData = await getData(`users/${user.uid}`);

        if (userData) {
          setIsAuth(true);
          setUserData(userData);
          localStorage.setItem('uid', user.uid);

          setLoading(false);

          alert('Login successful!');
        } else {
          alert('User data not found.');
          localStorage.removeItem('uid');
          await signOut(auth);

          setIsAuth(false);
          setUserData(null);
          setLoading(false);

        }
      } else {
        setLoading(false);

        alert('Error during login. Please try again');
        setIsAuth(false);
        setUserData(null);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setLoading(false);

      alert('An error occurred during login. Please try again later.');
      setIsAuth(false);
      setUserData(null);
    }
  };

  const handleLogout = async () => {
    setLoading(true)
    const auth = getAuth(firebaseApp);
    try {
      await signOut(auth).then(() => {
        setIsAuth(false);
        setUserData(null);
        localStorage.removeItem('uid');
        alert("Logout Successful")
        setLoading(false);

      });
    } catch (error) {
      console.error('Error during logout:', error);
      setLoading(false);

    }
  };


  const VisitorRoutes = [
    { path: '/:pid', element: <InvoicePayment /> },
    { path: '/NoticePage', element: <NoticePage /> },
    { path: '/', element: <LandingPage /> },
    { path: '/Pricing/', element: <PricingPage setLoading={setLoading} setIsAuth={setIsAuth} /> },
    { path: '/Payment/:planID', element: <DafatirPayment setLoading={setLoading} setIsAuth={setIsAuth} /> },

  ]

  const UserRoutes = {
    pending: [
      { path: '/:pid', element: <InvoicePayment /> },
      { path: '/NoticePage', element: <NoticePage /> },
      { path: '/', element: <AdminDashboard setIsAuth={setIsAuth} /> },
      { path: '/Account', element: <AccountSettings /> },
    ],
    approved: [
      { path: '/:pid', element: <InvoicePayment /> },
      { path: '/NoticePage', element: <NoticePage /> },
      { path: '/', element: <AdminDashboard /> },
      { path: '/Account', element: <AccountSettings /> },
      { path: '/Create', element: <InvoiceCreator /> },
      { path: '/Invoices/:oid', element: <InvoiceEditor /> },
    ]
  }

  const routes = isAuth
    ? userData?.status === 'approved'
      ? UserRoutes.approved
      : UserRoutes.pending
    : VisitorRoutes;

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={language === "en" ? 'App En' : 'App'}>
      <Alert alertData={alertData} setAlertData={setAlertData} />
      <Router>
        <Header isAuth={isAuth} handleLogin={handleLogin} handleLogout={handleLogout} alertData={alertData} setAlertData={setAlertData} setLoading={setLoading} />
        <Routes>
          {routes && routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
