import React, { useState } from 'react';
import "./CreateInfoForm.css";
import DeleteIcon from "../../Assets/DeleteIcon.svg"
import LockIcon from "../../Assets/LockIcon.svg"
import AddIcon from "../../Assets/AddIcon.svg"
import AppInput from '../Elements/AppInput';

export default function CreateInfoForm({ isEnglish, title, form, setForm }) {
  const [newInput, setNewInput] = useState("");

  const handleDeleteInput = (key) => {
    if(form[key].isLocked) return;
    const updatedForm = { ...form };
    delete updatedForm[key];
    setForm(updatedForm);
  };

  const handleAddNewField = () =>{
    if(newInput.trim() === "") return;
    setForm({ ...form, [newInput]: { value: "", isLocked: false, type:"text" } });
    setNewInput("");
  };

  return (
    <div className='CreateInfoForm'>
      <div className="FormTitle">* {title}</div>
      <div>
        {Object.entries(form).map(([key, item], i) => (
          <div className='CreateInfoFormInput' key={i}>
            <img className='CreateInfoFormInputIcon' src={item.isLocked? LockIcon : DeleteIcon} onClick={() => handleDeleteInput(key)} />
            <AppInput required className='AppInput' type={item.type} label={key} value={item.value} onChange={(e) => setForm({ ...form, [key]: { ...form[key], value: e.target.value } })} />
          </div>
        ))}
        <div className='CreateInfoFormInput CreateInputSubmit'>
          <img className='CreateInfoFormInputIcon' src={AddIcon} onClick={handleAddNewField} />
          <input className='AppInput BlueBorderBtn' type="text" value={newInput} onChange={(e) => setNewInput(e.target.value)} placeholder={isEnglish ? `new field` : `خانة جيدة`} />
        </div>
      </div>
    </div>
  );
}


// <input
// className='AppInput'
// type="text"
// value={Object.keys(item)[0]}
// onChange={(e) => {
//   const updatedForm = [...form]; // Create a copy of the form state array
//   updatedForm[i] = { [e.target.value]: "" }; // Update the specific item in the array

//   // Check if the current item is the last one and has a non-empty string value
//   if (i === updatedForm.length - 1 && e.target.value.trim() !== "") {
//     updatedForm.push({ "": "" }); // Add a new empty object to the end of the array
//   }

//   setForm(updatedForm); // Update the state with the modified array
// }}
// placeholder={isEnglish ? `New field` : `خانة إضافية`}
// />