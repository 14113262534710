import React from 'react';
import "./EmailList.css";
import SecondaryBtn from '../Buttons/SecondaryBtn';
import { useTranslation } from 'react-i18next';
import { postData } from '../../Fetch';

export default function EmailList() {
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.elements.email.value;
        if (email) {
            const data = { email: email };
            try {
                await postData('emailList', data);
                alert(t("Email added successfully"));
            } catch (error) {
                console.error("Error adding email: ", error);
            }
        }
        e.target.elements.email.value = '';
    };

    return (
        <form className='EmailListContainer' onSubmit={handleSubmit}>
            <div dangerouslySetInnerHTML={{ __html: t("Join email list") }} />
            <input required type="email" name="email" placeholder={t("Enter your email here")} />
            <SecondaryBtn isSubmit text={t("Submit")} type={"WhiteBorderBtn"} />
            <div className='FooterCopyWrite'>{t("Rights")}</div>
        </form>

    );
}
