import axios from "axios";
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get, push, set, remove } from "firebase/database";

const cloudUrl = "https://us-central1-gc-edu.cloudfunctions.net/api";

const createAdmin = async (email, password, name) => {
  try {
    // Get the current user
    const user = getAuth().currentUser;

    if (!user) {
      // Handle the case when the user is not authenticated
      console.log('User not authenticated. Please log in.');
      return;
    }

    // Send the request to the Cloud Function 
    const response = await axios.post(`${cloudUrl}/createAdmin`, {
      email: email,
      password: password,
      name: name,
      aid: localStorage.getItem("aid"),
    });

    console.log(response.data);
    return response.data;
    // Handle success or error response from the Cloud Function
  } catch (error) {
    console.error('Error invoking Cloud Function:', error);
    // Handle error
  }
};

const deleteSecondaryAdmins = async () => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteSecondaryAdmins`, {
      aid: localStorage.getItem("aid")
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteAdmin = async (did) => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteAdmin`, {
      aid: localStorage.getItem("aid"),
      did
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createUser = async (name, phone,level) => {
  try {
    // Get the current user
    const user = getAuth().currentUser;

    if (!user) {
      // Handle the case when the user is not authenticated
      console.log('User not authenticated. Please log in.');
      return;
    }

    // Send the request to the Cloud Function 
    const response = await axios.post(`${cloudUrl}/createUser`, {
      name,
      phone,
      level,
      aid: localStorage.getItem("aid")
    });

    console.log(response.data);
    return response.data;
    // Handle success or error response from the Cloud Function
  } catch (error) {
    console.error('Error invoking Cloud Function:', error);
    // Handle error
  }
};

const deleteAllUsers = async () => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteAllUsers`, {
      aid: localStorage.getItem("aid")
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (uid) => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteUser`, {
      aid: localStorage.getItem("aid"),
      uid
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteLevel = async (lid) => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteLevel`, {aid:localStorage.getItem("aid"), lid});
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteVideo = async (lid, vid) => {
  try {
    const response = await axios.post(`${cloudUrl}/deleteVideo`, {aid:localStorage.getItem("aid"), lid, vid});
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//******************************************************************************** */

// Function for making a GET request
const getData = async (endpoint) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    // Get the current authenticated user
    const user = auth.currentUser;
    const dataRef = ref(database, `${endpoint}`);
    if (user) {
      const authToken = await user.getIdToken();
      let snapshot = await get(dataRef, { auth: authToken });
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    } else {
      let snapshot = await get(dataRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    }
  } catch (error) {
    throw error;
  }
};

// Function for making a POST request
const postData = async (endpoint, data) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    const user = auth.currentUser;
    const authToken = user ? await user.getIdToken() : null;

    const dataRef = ref(database, `${endpoint}`);
    const newItemRef = push(dataRef);
    
    if (true) {
      await set(newItemRef, data, authToken);
    } else {
      await set(newItemRef, data);
    }
  } catch (error) {
    throw error;
  }
};

// Function for making a PUT request
const putData = async (endpoint, data) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    const user = auth.currentUser;
    const dataRef = ref(database, `${endpoint}`);
    const authToken = user ? await user.getIdToken() : null;

    if (true) {
      // You may need to adjust this logic based on your data structure
      await set(dataRef, data, authToken);
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    throw error;
  }
};

// Function for making a DELETE request
const deleteData = async (endpoint) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    const user = auth.currentUser;
    const dataRef = ref(database, `${endpoint}`);
    const authToken = user ? await user.getIdToken() : null;

    if (authToken) {
      // You may need to adjust this logic based on your data structure
      await remove(dataRef, authToken);
    } else {
      throw new Error("User not authenticated");
    }
  } catch (error) {
    throw error;
  }
};

export {
  getData, postData, putData, deleteData,
  createAdmin, deleteSecondaryAdmins, deleteAdmin,
  deleteAllUsers, deleteUser, createUser,
  deleteLevel, deleteVideo
};
