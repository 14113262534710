import React from 'react';
import AppInput from '../Elements/AppInput';
import "./InfoForm.css";
import { useTranslation } from 'react-i18next';

export default function InfoForm({ state, setState, title, form, ...restProps }) {
	const {i18n} = useTranslation();
	const isEnglish = i18n.language === "en";
	return (
		<div className='InfoForm'>
			<div className="FormTitle">* {title}</div>
			<div>
				{form && form.map((item, i) => (
					<AppInput
						key={i}
						value={state?.[item.name] || ''}
						onChange={(e) => setState({ ...state, [item.name]: e.target.value })}
						disabled={!item.isEditable}
						{...restProps}
						type={item.type}
						required={item.isRequired}
						label={isEnglish ? item.labelEn : item.labelAr}
					/>
				))}
			</div>
		</div>
	);
}

