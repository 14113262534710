import React, { useEffect, useState } from 'react'
import "./InvoiceEditor.css"
import InvoiceDetailsSection from '../Components/Invoice/InvoiceDetailsSection'
import DisplayInfoForm from '../Components/Forms/DisplayInfoForm'
import PrimaryBtn from '../Components/Buttons/PrimaryBtn'

import Dummy from "../Assets/Dummy.png" //delete later
import InvoiceTitle from '../Components/Invoice/InvoiceTitle'
import InvoiceSignature from '../Components/Invoice/InvoiceSignature'
import ButtonSection from '../Components/Sections/ButtonSection'
import DeleteIcon from '../Assets/DeleteIcon.svg'
import DownloadIcon from '../Assets/Download.svg'
import { deleteData, getData, putData } from '../Fetch'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../Components/Elements/Loading';

import { getStorage, ref, deleteObject } from 'firebase/storage';

export default function InvoiceEditor() {
	const navigate = useNavigate();
	const { oid } = useParams();
	const uid = localStorage.getItem('uid');
	const [invoiceData, setInvoiceData] = useState(null);
	const [status, setStatus] = useState(null);
	const [loading, setLoading] = useState(true);

	let isEnglish = false;

	useEffect(() => {
		const fetchInvoiceData = async () => {
			try {
				const data = await getData(`/users/${uid}/invoices/${oid}`);
				setInvoiceData(data);
				setStatus(data?.status);
				isEnglish = data?.language === "en";
				setLoading(false);
			} catch (error) {
				console.error('Error fetching invoice data:', error);
			}
		};

		fetchInvoiceData();
	}, [oid, uid]);


	const handleCancel = () => {
		// const response = window.confirm("Are you sure you want to cancel?")
		// if(response)
		navigate("/")
	}

	const handleDelete = async () => {
		const response = window.confirm("Are you sure you want to delete this invoice?")
		if (response) {
			try {
				setLoading(true);
				if(invoiceData.file){
					const storage = getStorage();
					const fileRef = ref(storage, `users/${uid}/invoices/${oid}/${invoiceData.file.name}`);
					await deleteObject(fileRef);
				}
				await deleteData(`/users/${uid}/invoices/${oid}`);
				await deleteData(`/links/${invoiceData.pid}`);
				setLoading(false);
				alert("deleted successfully")
				navigate("/")
			}
			catch (error) {
				console.error('Error deleting invoice:', error);
				setLoading(false);
			}
		}
	}

	const InputData = {
		sent: {
			textAr: "نسخ الرابط",
			textEn: "Copy Link",
			type: "LightBlueBtn",
			submit: (e)=>{
				e.preventDefault();
				navigator.clipboard.writeText(`dafatir.co/${invoiceData.pid}`);
				alert("copied");
			}
		},
		onReview: {
			textAr: "تأكيد الدفع",
			textEn: "Confirm",
			type: "OrangeBtn",
			submit: async(e)=>{
				e.preventDefault();
				try {
					// Submit invoice data
					await putData(`users/${uid}/invoices/${oid}/status`, "approved");
					setStatus("approved");
					alert("Invoice submitted successfully!");
				}
				catch (error) {
					// Display error alert
					alert("Failed to submit invoice. Please try again later.");
					console.error("Error submitting invoice:", error);
				}
			}
		},
		approved: {
			textAr: "تم التحقق",
			textEn: "Approved",
			type: "GreenBtn DisabledBtn",
			submit: (e)=>{e.preventDefault()}
		}
	}

	if (loading)
		return <Loading />

	return (
		<form className='InvoicePayment FlexColumn' onSubmit={InputData[status]?.submit}>

			<InvoiceTitle title={isEnglish ? "" : "إشعار دفع إلكتروني"} image={Dummy} />

			<div className="InvoiceDate">{isEnglish ? "" : "تاريخ الإصدار: 24/03/2024"}</div>

			<InvoiceDetailsSection info={invoiceData?.info} isEnglish={isEnglish} />

			<DisplayInfoForm form={invoiceData?.form} title={isEnglish ? "" : "بيانات العميل"} readOnly placeholder={isEnglish ? "Awaiting the client" : "بانتظار العميل ..."} isEnglish={isEnglish} />

			<ButtonSection onClick={()=>{if(invoiceData.file) window.open(invoiceData.file.link, '_blank')}} icon={DownloadIcon} message={isEnglish ? "" : "*الرجاء تحميل الإثبات والتحقق منه قبل الضغط على زر التأكيد*"} text={isEnglish ? "" : "تحميل الإثبات"} type="WhiteBtn" />

			<div className='TwoBtns'>
				<PrimaryBtn text={isEnglish ? "Cancel" : "العودة"} onClick={handleCancel} type="BlueBorderBtn" />
				<PrimaryBtn isSubmit text={isEnglish ? InputData[status]?.textEn : InputData[status]?.textAr} type={InputData[status]?.type} />
			</div>
			<div className='DeleteBtnContainer'>
				<PrimaryBtn onClick={handleDelete} icon={DeleteIcon} text={isEnglish ? "Delete" : "حذف"} type="RedBorderBtn" />
			</div>

			<InvoiceSignature image={Dummy} OwnerName={"Nuha - نُهى"} />
		</form>
	)
}
