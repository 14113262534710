import React from 'react';
import "./LanguageToggle.css";
import { useTranslation } from 'react-i18next';

export default function LanguageToggle({setLoading}) {
  const { i18n } = useTranslation();

  const handleLanguageToggle = () => {
		setLoading(true)
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
		localStorage.setItem("language", newLanguage);
		setTimeout(()=>setLoading(false), 1000)
  };

  return (
    <div className='ToggleContainer' onClick={handleLanguageToggle}>
      <div className={i18n.language === 'en' ? 'Toggle ActiveToggle' : 'Toggle'}>En</div>
      <div className={i18n.language === 'ar' ? 'Toggle ActiveToggle' : 'Toggle'}>ع</div>
    </div>
  );
}
