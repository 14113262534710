import React, { useState } from 'react';
import './TextareaWithAutoResize.css'; // Make sure to import your CSS file

export default function TextareaWithAutoResize({value, onChange, ...props}) {

	return (
		<div className="grow-wrap" data-replicated-value={value}>
			<textarea
				{...props}
				name="text"
				id="text"
				value={value}
				onChange={onChange}
				className="auto-resize"
				rows={1}
			></textarea>
		</div>
	);
}
