import React from 'react'
import "./MiniCard.css"

export default function MiniCard({ text, icon, notify, handleClick }) {
	return (
		<div onClick={handleClick? () => handleClick() : null} className='MiniCard'>
			{notify && <div className={notify==="Red"? 'MiniCardNotify NotifyRed': "MiniCardNotify NotifyYellow"}></div>}
			<div className='MiniCardContent'>
				<img className='MiniCardIcon' src={icon} alt="Mini Card Icon" />
				<div>{text}</div>
			</div>
		</div>
	)
}
