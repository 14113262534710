import React from 'react'
import "./NoticePage.css"

import PrimaryBtn from '../Components/Buttons/PrimaryBtn';
import InvoiceSignature from '../Components/Invoice/InvoiceSignature';
import Dummy from "../Assets/Dummy.png"

export default function NoticePage({ isEnglish }) {
	const title = isEnglish ? "Thank You" : "شكرًا لك"
	const body = isEnglish ? "" : "العملية قيد المراجعة، سيصلك إيصال بالدفع عبر البريد الإلكتروني بمجرد تأكيد العملية."
	const text = isEnglish ? "" : "الصفحة الرئيسية"
	return (
		<div className='NoticePage'>
			<div className="NoticeTitle">{title}</div>
			<div className="NoticeBody">{body}</div>
			<PrimaryBtn text={text} type="BlueBorderBtn" />
			<InvoiceSignature image={Dummy} OwnerName="Nuha - نُهى" />
		</div>
	)
}
