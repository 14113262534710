import React, { useEffect, useState } from 'react';
import "./Alert.css";
import PrimaryBtn from '../Buttons/PrimaryBtn';

export default function Alert({ alertData, setAlertData }) {
	const [isVisible, setIsVisible] = useState(alertData.isOn);

	useEffect(() => {
		if (alertData.isOn) {
			// Scroll to the top of the page
			window.scrollTo(0, 0);
			// Set overflow property of the body to hide overflow
			document.body.style.overflow = 'hidden';
			setIsVisible(true);
		} else {
			// Set visibility to false after the slide-out animation completes
			const alertElement = document.querySelector('.Alert');
			if (alertElement) {
				alertElement.addEventListener('animationend', handleAnimationEnd);
				return () => {
					alertElement.removeEventListener('animationend', handleAnimationEnd);
				};
			}
		}
	}, [alertData.isOn]);

	const handleAnimationEnd = () => {
		setIsVisible(false);
		document.body.style.overflow = 'auto';
	};

	const handleClick = () => {
		console.log("clicked");
		setAlertData({ ...alertData, isOn: false });
	};

	return (
		isVisible &&
		<div>
			<div className="AlertBg"></div>
			<div className="AlertContainer">
				<div className={alertData.isOn ? "Alert Open" : "Alert Leave"}>
					<div>
						<div className="AlertTitle">{alertData.title}</div>
						<div className="AlertBody">{alertData.body}</div>
						{
							alertData.type === "confirmation" ?
								<div className="TwoBtns">
									<PrimaryBtn text="إلغاء" type="BlueBorderBtn" />
									<PrimaryBtn handleClick={handleClick} text="موافق" type="BlueBtn" />
								</div>
								:
								<PrimaryBtn handleClick={handleClick} text="موافق" type="BlueBtn" />
						}
					</div>
				</div>
			</div>
		</div>
	);
}
