import React, { useState } from 'react'
import "./InvoiceCreator.css"
import PrimaryBtn from '../Components/Buttons/PrimaryBtn'
import InvoiceSignature from '../Components/Invoice/InvoiceSignature'
import InvoiceTitle from '../Components/Invoice/InvoiceTitle'

import Dummy from "../Assets/Dummy.png" //delete later
import InvoiceDetailsEditor from '../Components/Invoice/InvoiceDetailsEditor'
import CreateInfoForm from '../Components/Forms/CreateInfoForm'
import LanguageToggle from '../Components/Buttons/LanguageToggle'
import { getData, putData } from '../Fetch'
import { getAuth } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


export default function InvoiceCreator() {
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const language = i18n.language;
  const isEnglish = language === 'en';

  const [info, setInfo] = useState({});
  const [form, setForm] = useState(
    isEnglish ? { "Email": { value: "", isLocked: true, type:"email" }, "Name": { value: "", isLocked: true, type:"text" } }
      : { "الاسم": { value: "", isLocked: true, type:"text" }, "البريد الإلكتروني": { value: "", isLocked: true, type:"email" } }
  );

  const handleGoBack = () => {
    const response = window.confirm("Are you sure you want to cancel?")
    if (response) {
      navigate("/")
    }
  }

  const generateUniqueId = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const oidPrefix = 'i';
    let oid = oidPrefix;
    for (let i = 0; i < 4; i++) {
      oid += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return oid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const transformedForm = Object.fromEntries(
        Object.entries(form).map(([key, item]) => [key, item.value])
      );

      console.log(transformedForm)
      // return;
      const auth = getAuth();
      const uid = auth.currentUser.uid;

      // Generate unique invoice ID (oid)
      const oid = new Date().getTime(); // Using timestamp as a simple method for now, but consider UUIDs for better uniqueness

      // Generate unique link ID (pid)
      let pid;
      const generateUniquePid = async () => {
        let unique = false;
        while (!unique) {
          pid = generateUniqueId(); // Assuming generateUniqueId() generates a unique ID
          const snapshot = await getData(`links/${pid}`);
          if (!snapshot?.exists()) {
            unique = true;
          }
        }
      };

      // Generate current date
      const currentDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

      await generateUniquePid();

      // Submit invoice data
      await putData(`users/${uid}/invoices/${oid}`, {
        pid,
        status: "sent",
        language,
        info,
        dateIssued: currentDate,
        form: { "الاسم": "", ...transformedForm }
      });

      // Generate unique link ID and store link data

      await putData(`links/${pid}`, { uid, oid });

      // Display success alert
      alert("Invoice submitted successfully!");

      // Navigate to invoice page
      navigate(`/Invoices/${oid}`);
    } catch (error) {
      // Display error alert
      alert("Failed to submit invoice. Please try again later.");
      console.error("Error submitting invoice:", error);
    }
  };



  return (
    <form className='InvoicePayment FlexColumn' onSubmit={handleSubmit}>

      <InvoiceTitle title={isEnglish ? "" : "إشعار دفع إلكتروني"} image={Dummy} />

      <div className="InvoiceDate">{isEnglish ? "" : "تاريخ الإصدار: 24/03/2024"}</div>

      <div className='InvoiceLanguageToggle'>
        <LanguageToggle />
      </div>

      <InvoiceDetailsEditor state={info} setState={setInfo} />

      <CreateInfoForm form={form} setForm={setForm} title={isEnglish ? "Client info" : "معلومات العميل"} isEnglish={isEnglish} />

      <div className='TwoBtns'>
        <PrimaryBtn text={isEnglish ? "Cancel" : "العودة"} onClick={handleGoBack} type="BlueBorderBtn" />
        <PrimaryBtn isSubmit text={isEnglish ? "Create" : "إنشاء"} type="BlueBtn" />
      </div>
      <InvoiceSignature image={Dummy} OwnerName={"Nuha - نُهى"} />
    </form>
  )
}
