import React, { useState } from 'react';
import "./Testimonials.css";
import { useTranslation } from 'react-i18next';

export default function Testimonials() {
  const { t } = useTranslation();

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  
  const handlePrevSlide = () => {
    setActiveSlideIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  }
  const handleNextSlide = () => {
    setActiveSlideIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  }

  return (
    <div className='Testimonials'>
      <div className='SectionTitle'>{t("Clients Feedback")}</div>
      <div className='TestimonialsSlider'>
        {activeSlideIndex === 0 && <Slide handleNextSlide={handleNextSlide} handlePrevSlide={handlePrevSlide} title={t("Feedback1 title")} body={t("Feedback1 body")} />}
        {activeSlideIndex === 1 && <Slide handleNextSlide={handleNextSlide} handlePrevSlide={handlePrevSlide} title={t("Feedback2 title")} body={t("Feedback2 body")} />}
        {activeSlideIndex === 2 && <Slide handleNextSlide={handleNextSlide} handlePrevSlide={handlePrevSlide} title={t("Feedback3 title")} body={t("Feedback3 body")} />}
      </div>
      <div className='CirclesContainer'>
        <div className={`Circle ${activeSlideIndex === 0 ? 'ActiveCircle' : ''}`} onClick={() => setActiveSlideIndex(0)}></div>
        <div className={`Circle ${activeSlideIndex === 1 ? 'ActiveCircle' : ''}`} onClick={() => setActiveSlideIndex(1)}></div>
        <div className={`Circle ${activeSlideIndex === 2 ? 'ActiveCircle' : ''}`} onClick={() => setActiveSlideIndex(2)}></div>
      </div>
    </div>
  )
}

function Slide({ handleNextSlide, handlePrevSlide, title, body }) {
  return (
    <div className='Slide'>
      <div className='SlideTop'>
        <div onClick={handleNextSlide} className='LeftBtn'></div>
        <div className='SlideTitleContainer'>
          <div className='SlideTitle'>{title}</div>
          <div className='StarsIcons'></div>
        </div>
        <div onClick={handlePrevSlide} className='RightBtn'></div>
      </div>
      <div className='SlideBody'>{body}</div>
    </div>
  )
}