export const Plans = [
	{
		id: "1",
		isFavorite: false,
		titleAR: "الخطة الفضية",
		titleEN: "Silver Plan",
		listAR: ["إنشاء فواتير محدود (5/شهر)", 'تسجيل يدوي للفواتير', 'دعم عملاء فضي', 'إشتراك مدى الحياة'],
		listEN: ["Create your own community", 'Manage subscribers and join requests', 'A section for community posts and managing subscriber posts', 'DashboaRd and subscriber tracking'],
		price: "14",
		oldPrice: "21",
		currencyAR: "د.أ",
		currencyEN: "JD",
	},
	{
		id: "2",
		isFavorite: true,
		titleAR: "الخطة الذهبية",
		titleEN: "Golden Plan",
		listAR: ["إنشاء فواتير لا محدود", 'تسجيل آلي للفواتير', 'دعم عملاء ذهبي', 'إشتراك مدى الحياة'],
		listEN: ["Create your own community", 'Manage subscribers and join requests', 'A section for community posts and managing subscriber posts', 'DashboaRd and subscriber tracking'],
		price: "37",
		oldPrice: "117",
		currencyAR: "د.أ",
		currencyEN: "JD",
	}
]

export const ClientSupport = [
	{
		type: "text",
		labelAr: "الاسم",
		labelEn: "Name",
		isRequired: true,
		isEditable: true
	},
	{
		type: "email",
		labelAr: "الايميل",
		labelEn: "Email",
		isRequired: true,
		isEditable: false
	},
	{
		type: "text",
		labelAr: "العنوان",
		labelEn: "Address",
		isRequired: true,
		isEditable: true
	},
	{
		type: "text",
		labelAr: "رقم الهاتف",
		labelEn: "Mobile number",
		isRequired: true,
		isEditable: true
	},
]

export const PersonalSupport = [
	{
		type: "text",
		labelAr: "الاسم",
		labelEn: "Name",
		name: "name",
		isRequired: true,
		isEditable: true,
	},
	{
		type: "email",
		labelAr: "الايميل",
		labelEn: "Email",
		name: "email",
		isRequired: true,
		isEditable: false
	}
]

export const CliqSupport = [
	{
		type: "text",
		labelAr: "الاسم الرسمي",
		labelEn: "Official name",
		name: "officialName",
		isRequired: true,
		isEditable: true,
	},
	{
		type: "text",
		labelAr: "الاسم المستعار (Alias)",
		labelEn: "Alias",
		name: "alias",
		isRequired: true,
		isEditable: true,
	},
	{
		type: "text",
		labelAr: "اسم البنك",
		labelEn: "Bank name",
		name: "bankName",
		isRequired: true,
		isEditable: true,
	},
]

export const BankSupport = [
	{
		type: "text",
		labelAr: "الاسم الرسمي",
		labelEn: "Official name",
		name: "officialName",
		isRequired: true,
		isEditable: true,
	},
	{
		type: "text",
		labelAr: "رقم الحساب",
		labelEn: "Account number",
		name: "accountNumber",
		isRequired: true,
		isEditable: true,
	},
	{
		type: "text",
		labelAr: "الآيبان",
		labelEn: "IBAN",
		name: "iban",
		isRequired: true,
		isEditable: true,
	},
	{
		type: "text",
		labelAr: "Swift Code للحوالات الدولية (اختياري)",
		labelEn: "Swift Code for international transfers (optional)",
		name: "swiftCode",
		isRequired: false,
		isEditable: true,
	},
	{
		type: "text",
		labelAr: "اسم البنك",
		labelEn: "Bank name",
		name: "bankName",
		isRequired: true,
		isEditable: true,
	},
]
