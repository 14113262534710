import React, { useEffect, useState } from 'react'
import "./AdminDashboard.css"
import MiniCard from '../Components/Elements/MiniCard';
import Settings from "../Assets//Settings.svg"
import Link from "../Assets//Link.svg"
import { getData } from '../Fetch';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../Components/Elements/Loading';


export default function AdminDashboard() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState();

	const { t, i18n } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0)

		const fetchUserData = async () => {
			try {
				const uid = localStorage.getItem("uid")
				const userData = await getData(`users/${uid}`);
				if (userData) {
					setUserData(userData)
					setLoading(false);
				} else {
					alert(`an error occurred, please try again.`);
					setLoading(false);
					navigate(-1);
				}
			} catch (error) {
				console.error('Error fetching level data:', error);
				setLoading(false);
			}
		};
		fetchUserData();
	}, []);

	if (loading)
		return <Loading />

	return (
		<div className='AdminDashboard'>
			<div className='DashboardMiniCards'>
				<MiniCard
					handleClick={() => navigate('/Account')}
					notify={userData?.account?.isComplete === "false" ? "Red" : null}
					text={<span>إعدادات الدفع<br />الإلكتروني</span>}
					icon={Settings}
				/>
				<MiniCard
					handleClick={
						userData?.status === "approved" ?
							() => navigate('/Create')
							:
							() => alert("Your account is not approved yet. Please wait for approval.")}
					notify={userData?.status === "pending" ? "Yellow" : null}
					text={<span>إنشاء فاتورة<br />جديدة</span>}
					icon={Link}
				/>
			</div>
			<div className='SectionTitle'>سجل الفواتير</div>

			{
				userData?.invoices && Object.entries(userData.invoices)
					.sort(([, invoiceA], [, invoiceB]) => new Date(invoiceB.dateIssued) - new Date(invoiceA.dateIssued))
					.map(([key, invoice], i) => {
						return (
							<div className='InvoiceRecord' key={i} onClick={() => navigate(`/invoices/${key}`)} >
								<div className="RecordTitle">{invoice.info.title}</div>
								<div className='InvoiceStatusContainer'>
									<div className="RecordPrice">{i18n.language === "en" ? `${invoice.info.price.currency} ${invoice.info.price.amount}` : ` ${invoice.info.price.amount} ${invoice.info.price.currency}`}</div>
									<div className={`RecordStatus ${invoice.status}`}>{t(invoice.status)}</div>
								</div>
							</div>
						);
					})

			}
		</div>
	)
}
