import React from 'react'
import "./ListItem.css"

export default function ListItem({ text, color, children }) {
	return (
		<div className='ListItem'>
			<div>
				<span className={color === "Blue" ? 'ListBulletBlue' : "ListBulletBlack"}></span>
			</div>
			<div>{text}</div>
			{children}
		</div>
	)
}
