import React, { useState } from 'react';
import CopyInput from '../Elements/CopyInput';
import "./PaymentForm.css";
import { CliqSupport, BankSupport } from '../../SupportData';

export default function PaymentForm({ restProps, CliqDetails, BankDetails, activeMethod, setActiveMethod }) {

	const handleMethodClick = (method) => {
		setActiveMethod(method);
	};

	return (
		<div className='PaymentForm'>
			<div className="FormTitle">- معلومات الدفع</div>
			<div className='PaymentOptions'>
				<div onClick={() => handleMethodClick('Cliq')} className={activeMethod === 'Cliq' ? 'ActiveOption' : ''}>CLIQ كليك</div>
				<div onClick={() => handleMethodClick('Bank')} className={activeMethod === 'Bank' ? 'ActiveOption' : ''}>حوالة بنكية</div>
				<div className='DisabledOption'>VISA فيزا</div>
			</div>
			<div className='PaymentFormDetails'>
				<div className="PaymentFormTitle">الرجاء تحويل المبلغ للحساب التالي:</div>
				{activeMethod === 'Cliq' && CliqDetails && CliqSupport && CliqSupport.map((item, i) =>
					CliqDetails[item.name] && <CopyInput {...restProps} readOnly label={item.labelAr} value={CliqDetails[item.name]} key={i} />
				)}
				{activeMethod === 'Bank' && BankDetails && BankSupport && BankSupport.map((item, i) =>
					BankDetails[item.name] && <CopyInput {...restProps} readOnly label={item.labelAr} value={BankDetails[item.name]} key={i} />
				)}
			</div>
		</div>
	);
}
