import React, { useState } from 'react';
import './TextareaWithAutoResize.css'; // Make sure to import your CSS file
import ListItem from './ListItem';

export default function BulletTextareaWithAutoResize({ state, setState, value, onChange, ...props }) {
	const [isEditing, setIsEditing] = useState(false); // Track if editing mode is enabled

	const handleChange = (e) => {
		setState({ ...state, list: e.target.value});
		handleKeyUp(e)
	};

	const handleKeyUp = (e) => {
		const textarea = e.target;
		const lines = textarea.value.split('\n');
		const bullet = '•'; // Define bullet symbol

		// Loop through each line and prepend bullet and spaces if needed
		const newText = lines
			.map((line, index) => {
				// Determine indentation based on the line's position
				const indentation = index > 0 ? '' : ''; // Add two spaces for lines after the first

				// Check if line already starts with a bullet
				if (line.trim().startsWith(bullet)) {
					return line; // If line starts with bullet, no need to modify
				} else {
					// Add bullet symbol and indentation
					return indentation + (line.trim() ? bullet + ' ' + line : ''); // Add bullet only if line is not empty
				}
			})
			.join('\n');

		setState({ ...state, list: newText});
	};

	const handleBlur = () => {
		setIsEditing(false); // Set blur state to true when textarea is blurred
	};

	const handleEditClick = () => {
		setIsEditing(true); // Enable editing mode when edit button is clicked
	};

	const handleListItemClick = () => {
		setIsEditing(true); // Enable editing mode when list item is clicked
	};

	return (
		<div>
			{isEditing || !state?.list ? (
				<div className="grow-wrap" data-replicated-value={state?.list}>
					<textarea
						{...props}
						name="text"
						id="text"
						value={state?.list}
						onChange={handleChange}
						onBlur={handleBlur}
						className="auto-resize"
						rows={1}
					></textarea>
				</div>
				) : (
					<div className='DetailsList' 
					onClick={handleEditClick}
					onMouseEnter={handleEditClick}
					>
							{state?.list?.split('\n').map((line, index) => {
									const cleanedLine = line.replace(/^\s*•\s*/, ''); // Remove bullets and spaces at the beginning of the line
									return <ListItem key={index} text={cleanedLine.trim()} onClick={handleListItemClick} />;
							})}
					</div>
			)}
		</div>
	);
}
