import React from 'react'
import "./InvoiceDetailsSection.css"
import ListItem from '../Elements/ListItem';

import { useTranslation } from 'react-i18next';

export default function InvoiceDetailsSection({ info }) {
	const { i18n } = useTranslation();
	const isEnglish = i18n?.language === "en";

	const title = info?.title;
	const list = info?.list;
	const currency = info?.price?.currency;
	const price = isEnglish ? `${currency} ${info?.price?.amount}` : ` ${info?.price?.amount} ${currency}`;


	return (
		<div className='InvoiceDetailsSection'>
			<div className="InvoiceDetailsTop">
				<div className="InvoiceDetailsTitle">{title}</div>
				<div className='InvoicePrice'>{price}</div>
			</div>
			<div className="InvoiceDetails">
				{list?.split('\n').map((line, index) => {
					const cleanedLine = line.replace(/^\s*•\s*/, ''); // Remove bullets and spaces at the beginning of the line
					return <ListItem key={index} text={cleanedLine.trim()} />;
				})}
			</div>
		</div>
	)
}
