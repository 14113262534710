import React from 'react';
import AppInput from '../Elements/AppInput';
import "./InfoForm.css";
import { useTranslation } from 'react-i18next';

export default function DisplayInfoForm({ state, setState, title, form, ...restProps }) {
	const {i18n} = useTranslation();
	const isEnglish = i18n.language === "en";
	return (
		<div className='InfoForm'>
			<div className="FormTitle">* {title}</div>
			<div>
				{form && Object.entries(form).map(([key, value], i) => (
					<AppInput
						key={i}
						label={key}
						value={value}
						readOnly
						// onChange={(e) => setState({ ...state, key: e.target.value })}
						// disabled={!item.isEditable}
						{...restProps}
						// type={item.type}
						// required={item.isRequired}
					/>
				))}
			</div>
		</div>
	);
}

