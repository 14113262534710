import React, { useEffect, useState } from 'react'
import InvoiceDetailsSection from '../Components/Invoice/InvoiceDetailsSection'
import InfoForm from '../Components/Forms/InfoForm'
import PaymentForm from '../Components/Forms/PaymentForm'
import PrimaryBtn from '../Components/Buttons/PrimaryBtn'

import Dummy from "../Assets/Dummy.png" //delete later
import InvoiceTitle from '../Components/Invoice/InvoiceTitle'
import InvoiceSignature from '../Components/Invoice/InvoiceSignature'
import ButtonSection from '../Components/Sections/ButtonSection'
import BlueUploadIcon from "../Assets/Upload.svg"
import WhiteUploadIcon from "../Assets/WhiteUpload.svg"

import { useNavigate, useParams } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getData, putData } from '../Fetch'

import { BankSupport, ClientSupport, CliqSupport, Plans } from '../SupportData'
import Loading from '../Components/Elements/Loading';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function DafatirPayment({ setIsAuth }) {

	const { planID } = useParams();
	const plan = Plans.find(plan => plan.id === planID);

	const navigate = useNavigate();
	const [adminInfo, setAdminInfo] = useState(true);
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState({});
	const [file, setFile] = useState(null);
	const [fileAlert, setFileAlert] = useState(false);
	const [activeMethod, setActiveMethod] = useState('Cliq');

	const CurrentDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

	useEffect(() => {
		setLoading(true);
		window.scrollTo(0, 0);
		const auth = getAuth();

		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				setFormData({ ...formData, Email: user?.email, Name: user?.displayName, "Mobile number": user?.phoneNumber });
				try {
					const adminInfo = await getData(`admin/account`);
					setAdminInfo(adminInfo);
					console.log(adminInfo)
				}
				catch (error) {
					console.error('Error fetching admin data:', error);
				}
				setLoading(false);
			} else {
				alert("Please login first");
				navigate("/Pricing");
				setLoading(false);
			}
		});
		setLoading(false);
		return () => unsubscribe(); // Cleanup function
	}, []);

	const handleHelp = () => {
		//navigate to whatsapp link with asking for help message
		window.open("https://wa.me/962791807251?text=I%20need%20help%20with%20Dafatir%20payment", "_blank")
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!file) {
			setFileAlert(true);
			return;
		}

		try {
			setLoading(true)

			const auth = getAuth();
			const user = auth.currentUser;
			localStorage.setItem('uid', user.uid);

			// Check file size
			if (file.size > 2 * 1024 * 1024) {
				alert('File size should be less than 2MB.');
				return;
			}

			// Upload file to Firebase Storage
			const storage = getStorage();
			const storageRef = ref(storage, `admin/invoices/${user.uid}/${file.name}`);
			await uploadBytes(storageRef, file);

			// Get download URL
			const downloadURL = await getDownloadURL(storageRef);

			// Prepare data for submission
			const currentDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

			const userData = { account: { name: formData?.Name, email: user?.email, isComplete: false }, status: "pending" };
			const invoiceData = { ...plan, ...formData, dateIssued: currentDate, status: "pending", file: { link: downloadURL, name: file.name }, method: activeMethod };

			await putData(`users/${user.uid}`, userData);

			// Put invoice data
			await putData(`admin/invoices/${user.uid}`, invoiceData);

			console.log("Data submitted successfully!");

			setIsAuth(true)
			setTimeout(() => { navigate("/"); setLoading(false); }, 1000)

		} catch (error) {
			console.error("Error submitting data:", error);
			// Handle error
		}
		setTimeout(() => { setLoading(false); }, 1000)
	};


	const handleUpload = (event) => {
		const uploadedFile = event.target.files[0];
		setFile(uploadedFile);
	};

	if (loading) return <Loading />

	return (
		<form className='InvoicePayment FlexColumn' onSubmit={handleSubmit}>

			<InvoiceTitle title={false ? "" : "إشعار دفع إلكتروني"} image={Dummy} />

			<div className="InvoiceDate">{false ? "" : "تاريخ الإصدار:"} {CurrentDate}</div>

			<InvoiceDetailsSection info={plan} />

			<InfoForm state={formData} setState={setFormData} title={false ? "" : "بيانات العميل"} form={ClientSupport} />

			<PaymentForm CliqDetails={adminInfo?.cliq} BankDetails={adminInfo?.bank} activeMethod={activeMethod} setActiveMethod={setActiveMethod} />

			<input type="file" accept="image/*,.pdf,.doc,.docx" onChange={handleUpload} style={{ display: 'none' }} />
			<ButtonSection handleClick={() => { document.querySelector('input[type="file"]').click(); }} icon={file ? WhiteUploadIcon : fileAlert ? WhiteUploadIcon : BlueUploadIcon} message={file ? "*تم الرفع*" : "*الرجاء إرفاق ملف أو صورة تثبت تحويل المبلغ*"} text={false ? "" : "رفع ملف/صورة"} type={file ? "GreenBtn" : fileAlert ? "RedBtn" : "WhiteBtn"} />

			<div className='TwoBtns'>
				<PrimaryBtn text={false ? "Need Help" : "أحتاج مساعدة"} handleClick={handleHelp} type="BlueBorderBtn" />
				<PrimaryBtn isSubmit text={false ? "Confirm" : "تم التحويل"} type="BlueBtn" />
			</div>
			<InvoiceSignature image={Dummy} OwnerName={"Nuha - نُهى"} />
		</form>
	)
}
