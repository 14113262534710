import React from 'react';
import "./AppInput.css"

export default function AppInput({ isEnglish, label, ...restProps }) {
	return (
		<div className='AppInputContainer'>
			<div className="AppLabel">{label}</div>
			<input className='AppInput' placeholder={isEnglish? `write ${label.toLowerCase()} here`:`أدخل ${label} هنا`} {...restProps} />
		</div>
	);
}