import React, { useEffect, useState } from 'react'
import PrimaryBtn from '../Components/Buttons/PrimaryBtn';
import InfoForm from '../Components/Forms/InfoForm';
import "./AccountSettings.css"
import UploadIcon from "../Assets/Upload.svg"
import Dummy from "../Assets/Dummy.png"
import AppDivider from '../Components/Elements/AppDivider';
import { BankSupport, CliqSupport, PersonalSupport } from '../SupportData';
import { useTranslation } from 'react-i18next';
import { getData, putData } from '../Fetch';
import { useNavigate } from 'react-router-dom';
import Loading from '../Components/Elements/Loading';

export default function AccountSettings() {
	const navigate = useNavigate();
	const [personalData, setPersonalData] = useState({});
	const [cliqData, setCliqData] = useState({});
	const [bankData, setBankData] = useState({});
	const [loading, setLoading] = useState(true);

	const {i18n} = useTranslation();
	const isEnglish = i18n.language === "en";

	useEffect(() => {
		window.scrollTo(0, 0)

		const fetchUserData = async () => {
			try {
				const uid = localStorage.getItem("uid")
				const userData = await getData(`users/${uid}/account`);
				if (userData) {
					setPersonalData(userData.personal);
					setCliqData(userData.cliq);
					setBankData(userData.bank);
					setLoading(false);
				} else {
					alert(`An error occurred, please try again.`);
					setLoading(false);
					navigate(-1);
				}
			} catch (error) {
				console.error('Error fetching level data:', error);
				setLoading(false);
			}
		};
		fetchUserData();
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			const uid = localStorage.getItem("uid");
			await putData(`users/${uid}/account`, {isComplete: true, personal: {...personalData}, cliq: {...cliqData}, bank: {...bankData}});
			alert('Account settings updated successfully!');
			navigate('/');
		} catch (error) {
			console.error('Error updating account settings:', error);
			alert('An error occurred while updating account settings. Please try again.');
		}
	};

	if(loading)
		return <Loading />

	return (
		<form className='AccountSettings' onSubmit={handleSubmit}>
			<div className='SectionTitle'>{isEnglish ? "Account Settings" : "إعدادات الحساب"}</div>
			<div className='AdjustWidth'>
				<div className='UploadProfilePic'>
					<img className='ProfilePic' src={Dummy} alt='ProfilePic'></img>
					<PrimaryBtn icon={UploadIcon} text={isEnglish ? "" : "صورة جديدة"} type="WhiteBtn" />
				</div>
			</div>
			<div className="InfoFormsContainer">
				<InfoForm state={personalData} setState={setPersonalData} title={isEnglish ? "" : "البيانات الشخصية"} form={PersonalSupport} />
				<AppDivider />
				<InfoForm state={cliqData} setState={setCliqData} title={isEnglish ? "" : "بيانات الدفع كليك CLIQ "} form={CliqSupport} />
				<AppDivider />
				<InfoForm state={bankData} setState={setBankData} title={isEnglish ? "" : "بيانات الدفع تحويل بنكي "} form={BankSupport} />
			</div>
			<div className='TwoBtns'>
				<PrimaryBtn text={isEnglish ? "Back" : "العودة"} onClick={()=> navigate('/')} type="BlueBorderBtn" />
				<PrimaryBtn isSubmit text={isEnglish ? "Save Changes" : "حفظ التعديلات"} type={"BlueBtn"} />
			</div>
		</form>
	)
}
